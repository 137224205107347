import React, { useState } from "react";
import { useTranslation } from "@frontend/app/hooks";
import Text from "../Text";

const PASS_CODE_REGEX = /^[a-zA-Z0-9]+$/;
const PASS_CODE_LENGTH = 8;

const PassCodeForm: React.FC = () => {
  const [passCodeFirstPart, setPassCodeFirstPart] = useState<string>("");
  const [passCodeSecondPart, setPassCodeSecondPart] = useState<string>("");

  const handlePasteFromClipboard = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const pastedText = (event.clipboardData || (window as any).clipboardData).getData("text");

    const cleanPassCode = pastedText.split("").reduce((prev, curr) => {
      if (PASS_CODE_REGEX.test(curr)) {
        return prev + curr;
      }
      return prev;
    }, "");
    setPassCodeFirstPart(cleanPassCode.substring(0, 4));
    setPassCodeSecondPart(cleanPassCode.substring(4));
  };

  const handleBackspaceKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key.toLowerCase() === "backspace" && passCodeSecondPart.length === 0) {
      (document as any).getElementById("pass-code-input-1").focus();
    }
  };

  const canDisable = (): boolean => {
    return (
      PASS_CODE_REGEX.test(passCodeFirstPart) === false ||
      PASS_CODE_REGEX.test(passCodeSecondPart) === false ||
      passCodeFirstPart.length + passCodeSecondPart.length < PASS_CODE_LENGTH
    );
  };

  const getPassCode = (): string => {
    return `${passCodeFirstPart}${passCodeSecondPart}`;
  };
  const translation = useTranslation();

  return (
    <form
      method="post"
      target="_self"
      id="pass-code-form"
      data-testid="pass-code-form"
      name="pass-code-form"
      className="form"
    >
      {translation("PASSCODE_FORM_TITLE")?.trim() ? (
        <h2 className="title">
          <Text translationKey="PASSCODE_FORM_TITLE" />
        </h2>
      ) : null}
      <p className="description">
        <Text translationKey="PASSCODE_FORM_DESCRIPTION" />
      </p>

      <div className="fields">
        <div className="field">
          <div className="field__label">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              <Text translationKey="ACCESS_CODE" />
            </label>
          </div>
          <div id="pass-code-inputs" className="field__input">
            <input id="passcode" type="hidden" name="passcode" value={getPassCode()} />
            <input
              tabIndex={0}
              id="pass-code-input-1"
              maxLength={4}
              placeholder={"X".repeat(4)}
              value={passCodeFirstPart}
              onChange={(event) => setPassCodeFirstPart(event.target.value)}
              onPaste={handlePasteFromClipboard}
            />
            <input
              tabIndex={0}
              id="pass-code-input-2"
              maxLength={4}
              placeholder={"X".repeat(4)}
              value={passCodeSecondPart}
              onChange={(event) => setPassCodeSecondPart(event.target.value)}
              onKeyUp={handleBackspaceKey}
            />
          </div>
          <div className="error-message" />
        </div>
      </div>

      <button disabled={canDisable()} type="submit" id="submitBtn" className="login__form__submit">
        <Text translationKey="SUBMIT_LOGIN_LABEL" />
      </button>
    </form>
  );
};

export default PassCodeForm;
